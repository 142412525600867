<template>
  <div class="row justify-content-center">
    <home-header></home-header>
    <vs-prompt
      title=""
      @accept="deleteActivity"
      accept-text="ลบ"
      cancel-text="ยกเลิก"
      @close="close"
      :active.sync="deletePrompt"
    >
      <div class="con-exemple-prompt">
        ลบข้อมูลนี้?
      </div>
    </vs-prompt>

    <vs-prompt
      color="primary"
      title="แจ้งแขกวีไอพี"
      accept-text="บันทึก"
      cancel-text="ยกเลิก"
      @cancel="(formModel.dttm = ''), (formModel.desc = '')"
      @accept="acceptAlert"
      @close="close"
      :is-valid="validName"
      :active.sync="activePrompt2"
    >
      <div class="con-exemple-prompt">
        กรุณากรอกข้อมูลเบื้องต้นของแขกวีไอพี
        <datepicker
          :highlighted="highlightedFn"
          icon="event"
          name="dttm"
          placeholder="วันที่จอง"
          :language="languages['th']"
          format="d MMMM yyyy"
          v-model="formModel.dttm"
        ></datepicker>
        <vs-input
          type="text"
          icon="mode_edit"
          placeholder="หมายเลขทะเบียนรถ"
          v-model="formModel.desc"
          style="margin-top:6px;"
        />
        <vs-alert :active="!validName" color="danger" icon="new_releases">
          กรุณากรอกข้อมูลให้ครบถ้วน
        </vs-alert>
      </div>
    </vs-prompt>
    <div v-if="enable">
      <vs-list>
        <vs-list-item class="k-nav-bar" title="แขกวีไอพี" subtitle="">
          <vs-button @click="activePrompt2 = true" color="primary" type="filled"
            >แจ้งแขกวีไอพี</vs-button
          >
        </vs-list-item>
      </vs-list>
      <vs-card style="margin-top: 10px">
        <div>
          <vs-table stripe :data="activityItems">
            <template slot="thead" style="margin-top: 100px">
              <vs-th> ลบ </vs-th>
              <vs-th> วันที่จอง </vs-th>
              <vs-th> ทะเบียนรถ </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <vs-button
                    @click="openDeletePromt(data[indextr].key)"
                    color="primary"
                    type="flat"
                    icon="delete"
                  ></vs-button>
                </vs-td>
                <vs-td :data="data[indextr].dttm">
                  {{
                    new Date(data[indextr].dttm.seconds * 1000)
                      | moment("dddd, Do MMMM YYYY")
                  }}
                </vs-td>
                <vs-td :data="data[indextr].desc">
                  {{ data[indextr].desc }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div>
            <br />
            <paginate
              v-model="page"
              :page-count="total"
              :page-range="3"
              :margin-pages="2"
              :click-handler="inputData"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-class="'page-item'"
            >
            </paginate>
          </div>
        </div>
      </vs-card>
    </div>
    <div v-else>
      <the-advertising :post-details="listAdvertising" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
import VueCookies from "vue-cookies";
import { db } from "../store/firebaseDb";
const moment = require("moment");
require("moment/locale/th");
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import HomeHeader from "@/layouts/components/custom/HomeHeader.vue";
import Paginate from "vuejs-paginate";
import TheAdvertising from "@/components/advertising/TheAdvertising.vue";
import ListAdvertising from "@/store/listAdvertising.js";
console.log("lang", lang);

Vue.use(VueCryptojs);
Vue.use(VueCookies);
Vue.component("home-header", HomeHeader);
Vue.component("paginate", Paginate);
Vue.component("the-advertising", TheAdvertising);
moment.locale("th");
Vue.use(require("vue-moment"), {
  moment
});

console.log(Vue.moment().locale());
export default {
  data() {
    return {
      deletePrompt: false,
      language: "en",
      languages: lang,
      houseInfo: {},
      activityItems: [],
      activePrompt2: false,
      val: "",
      hNumber: "",
      formModel: {
        dttm: "",
        desc: ""
      },
      highlightedFn: {
        customPredictor(date) {
          const now = moment(new Date()).format("MMMM Do YYYY");
          const d = moment(date).format("MMMM Do YYYY");
          console.log("now=", now);
          console.log("d=", d);
          if (now == d) {
            return true;
          }
        }
      },
      // pagination
      total: 0, // จำนวนหน้า
      limit: 10,
      page: 1,
      totalItems: 0, // จำนวนข้อมูลทั้งหมด
      isLoad: true,
      // lastItem: null,

      user_cloud_config: {},
      enable: null,
      listAdvertising: {},
      show_per_page: 10,
    };
  },
  computed: {
    validName() {
      console.log(this.formModel);
      return this.formModel.dttm != null && this.formModel.desc.length > 0;
    }
  },
  methods: {
    documentPath() {
      const customerName = $cookies.get("customer");
      const _documentPath = "customer/" + customerName + "/vip";
      return _documentPath;
    },

    isHavePrefixAdddress() {
      return !(
        this.houseInfo.prefix_address == null ||
        this.houseInfo.prefix_address == ""
      );
    },

    getDbWithCondition_x() {
      this.hNumber = $cookies.get("home");
      return db.collection(this.documentPath())
        .where("house_no", "==", this.hNumber + "")
    },

    confirmDeleteRecord() {},

    deleteActivity() {
      const customerName = $cookies.get("customer");
      db.collection(this.documentPath())
        .doc(this.deleteId)
        .delete()
        .then(() => {
          this.getTotalRow() ;
          console.log("Document deleted!");
        })
        .catch(error => {
          console.error(error);
        });
    },

    openDeletePromt(id) {
      this.deleteId = id;
      this.deletePrompt = true;
    },

    acceptAlert(color) {
      const customerName = $cookies.get("customer");
      this.hNumber = $cookies.get("home");

      this.formModel.house_no = this.hNumber;
      this.formModel.type = 0;
      this.formModel.subtitle = "";
      this.formModel.title = "";
      this.formModel.activity = "";

      if (this.isHavePrefixAdddress()) {
        this.formModel.prefix_address = this.houseInfo.prefix_address;
      } else {
        this.formModel.prefix_address = "";
      }

      event.preventDefault();
      // db.collection("customer").document(customerName).collection("house")
      db.collection(this.documentPath())
        .doc()
        .set(this.formModel)
        .then(() => {
          //alert("Update successfully!");
          // this.user.name = ''
          this.formModel.dttm = null;
          this.formModel.desc = "";
          this.getTotalRow() ;
          this.$vs.notify({
            color: "danger",
            title: "",
            text: "บันทึกข้อมูลสำเร็จแล้ว"
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    close() {
      this.formModel.dttm = null;
      this.formModel.desc = "";
      // this.$vs.notify({
      //   color:'danger',
      //   title:'Closed',
      //   text:'You close a dialog!'
      // })
    },

    async getTotalRow_x() {
      let totalCustomer = await this.getDbWithCondition()
        .get()
        .then(document => document.docs.length);
      this.total = Math.ceil(totalCustomer / this.limit);
      this.totalItems = totalCustomer;
    },


  async getLastItem() {
      console.log("[[getLastItem]]");

      var _limit = (this.page - 1) * this.show_per_page;
      console.log("this.page=" + this.page);
      console.log("this.show_per_page=" + this.show_per_page);
      console.log("_limit=" + _limit);

      if (_limit == 0) return null;

      this.hNumber = $cookies.get("home");
      var reference = null;

      if (this.isHavePrefixAdddress()) {
        reference = db
          .collection(this.documentPath())
          .where("house_no", "==", this.hNumber + "")
          .where("prefix_address", "==", this.houseInfo.prefix_address + "");
      } else {
        reference = db
          .collection(this.documentPath())
          .where("house_no", "==", this.hNumber + "")
          .where("prefix_address", "==", "");
      }

      reference = reference.orderBy("dttm", "desc");
      reference = reference.limit(_limit);

      const snapshot = await reference.get();
      console.log("snapshot.docs.length=" + snapshot.docs.length);

      const last = snapshot.docs[snapshot.docs.length - 1];
      return last;
    },

    async getTotalRow() {
      let totalCustomer = await this.getDbWithCondition(true, null)
        .get()
        .then((document) => document.docs.length);

      console.log("totalCustomer=" + totalCustomer);

      this.total = Math.ceil(totalCustomer / this.show_per_page);
      this.totalItems = totalCustomer;
    },

    getDbWithCondition(isForCountAll = false, lastItem) {
      console.log("[[getDbWithCondition]]");

      this.hNumber = $cookies.get("home");
      var reference = null;

      if (this.isHavePrefixAdddress()) {
        reference = db
          .collection(this.documentPath())
          .where("house_no", "==", this.hNumber + "")
          .where("prefix_address", "==", this.houseInfo.prefix_address + "");
      } else {
        reference = db
          .collection(this.documentPath())
          .where("house_no", "==", this.hNumber + "")
          .where("prefix_address", "==", "");
      }

      if (isForCountAll == true) {
        return reference;
      }

      reference = reference.orderBy("dttm", "desc");

      console.log("this.show_per_page=" + this.show_per_page);

      if (lastItem != null) {
        reference = reference.startAfter(lastItem).limit(this.show_per_page);
      } else {
        reference = reference.limit(this.show_per_page);
      }

      return reference;
    },

  async listActivity({ page = 1 }) {
      console.log("page=" + page);

      this.isLoad = true;

      this.getTotalRow();

      this.getLastItem().then((lastItem) => {
        console.log("lastItem", lastItem);

        this.getDbWithCondition(false, lastItem).onSnapshot(
          (snapshotChange) => {

            this.activityItems = [];

            snapshotChange.forEach((doc) => {

              this.activityItems.push({
              key: doc.id,
              activity: doc.data().activity,
              desc: doc.data().desc,
              dttm: doc.data().dttm
              });

              this.isLoad = false;
            });
          }
        );
      });
      // console.log("last=",last);
    },

    listActivity_x() {
      this.getTotalRow();
      this.getDbWithCondition()
        .orderBy("dttm", "desc")
        .onSnapshot(snapshotChange => {
          this.activityItems = [];
          snapshotChange.forEach(doc => {
            // console.log("list doc.data()", doc.data());
            this.activityItems.push({
              key: doc.id,
              activity: doc.data().activity,
              desc: doc.data().desc,
              dttm: doc.data().dttm
            });
            this.isLoad = false;
          });
        });
    },

    previousData: function () {
      this.listActivity({ page: this.page });
    },
    nextData: function () {
      this.listActivity({ page: this.page });
    },
    inputData: function (value) {
      this.page = value;
      this.listActivity({ page: this.page });
    },
    fetchHome_x: async function({ page = 0, limit = 10 }) {
      this.isLoad = true;
      let indexOf = limit * page;
      this.limit = limit;
      this.getDbWithCondition()
          .orderBy("dttm", "desc")
          .then(document => {
          this.lastItem = document.docs[indexOf];
          this.getDbWithCondition()
            .startAt(this.lastItem)
            .limit(this.limit)
            .orderBy("dttm", "desc")
            .onSnapshot(snapshotChange => {
              this.activityItems = [];
              snapshotChange.forEach(doc => {
                this.activityItems.push({
                  key: doc.id,
                  activity: doc.data().activity,
                  desc: doc.data().desc,
                  dttm: doc.data().dttm
                });
                this.isLoad = false;
              });
            });
        });
    }
  },
  components: {
    Datepicker
  },
  created() {
    this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
    var parameters = this.$route.query;

    var home = $cookies.get("home");
    console.log("home", home);

    this.houseInfo = $cookies.get("houseinfo");
    this.hNumber = $cookies.get("home");
    console.log("this.houseInfo vip=", this.houseInfo);

    if (
      typeof this.houseInfo.active !== "undefined" ||
      this.houseInfo.active !== null
    ) {
      console.log("this.houseInfo.active => ", this.houseInfo.active);
      if (this.houseInfo.active === false) {
        this.$vs.notify({
          title: "<center>VCar Park</center>",
          text: "ไม่มี สิทธิ์ใช้งานส่วนเสริม",
          color: "danger",
          position: "top-center"
        });
        setTimeout(() => this.$router.push({ path: "/pages/error-403" }), 2000);
      }
    }

    this.listActivity({ page: this.page });
    this.user_cloud_config = $cookies.get("user_cloud_config");
    console.log("this.user_cloud_config vip=", this.user_cloud_config);

    this.enable = this.user_cloud_config.enable_vip;
    this.listAdvertising = ListAdvertising.page_vip;
  }
};
</script>

<style>
.con-exemple-prompt {
  padding: 10px;
  padding-bottom: 0px;
}
.vs-input {
  width: 100%;
  margin-top: 10px;
}
</style>
